module.exports = [{
      plugin: require('../plugins/gatsby-source-dynamodb-query/gatsby-browser.js'),
      options: {"plugins":[],"typeName":"inventory","region":"us-east-2","tableName":"AdjustedInventory","dealerId":["138124"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"431bd8ab74ea43f9e73b542bc2607503"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
